import React, { useState, useRef } from 'react'
import { Title } from 'utils/Title'
import { errorHandler } from 'utils/errorHandler'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import UsersList from './UsersList'
import { FaBolt, FaPlus } from "react-icons/fa";
import ModalUser from 'components/modals/ModalUser'
import PageMenuHeader from 'components/PageMenuHeader'
import PageMainHeader from 'components/PageMainHeader'
import Banner from '@leafygreen-ui/banner'
import Button from '@leafygreen-ui/button'
import ScrollTop from 'utils/ScrollTop'
import { AuthContext } from 'contexts/AuthContext'
import { fetchUsers } from 'utils/auth'
import _ from "lodash"
import axios from 'axios'

const title = 'Users'

const initialUser = {
  username: "",
  password: "",
  role: "",
}

export default function Users() {
  const [selectedUser, setSelectedUser] = useState(initialUser);
  const [msg, setMsg] = useState(null);
  const [mode, setMode] = useState("_createUser");
  const modeRef = useRef("_createUser");
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { user } = React.useContext(AuthContext);


  // console.log("[Users] user: ", user);
  const queryClient = useQueryClient();

  const { data: users, isLoading, isError, error } = useQuery("users",
  () => {
    let query = {}
    if(user.username !== "super") query = {username: {$ne: "super"}}
    return fetchUsers(query)
  }, {
    refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5
  })

  // console.log('- selectedUser: ', selectedUser)

  const showModalHandler = ({selectedUser, mode}) => {
    console.log("* showModalHandler init");
    console.log("[showModalHandler] mode: ", mode);
    setMode(mode);
    modeRef.current = mode;
    if (msg) setMsg(null);
    if (selectedUser) {setSelectedUser(selectedUser)} else {setSelectedUser(initialUser)};
    setModalIsOpen(true)
  };

  const mutateUser = useMutation(async () => {
    console.log("* mutateUser init");
    console.log("[mutateUser] mode: ", mode);
    console.log("[mutateUser] modeRef: ", modeRef);
    let result;
    if(window?.printflo_api) {
      result = await window.printflo_api[modeRef.current](selectedUser)
      if(_.isError(result)) throw result;

    } else {
      try {
        modeRef.current = _.replace(modeRef.current, "_", "").replace("User", "");
        console.log("[mutateUser] modeRef.current:replace ", modeRef.current, typeof modeRef.current);
        let url = `/api/auth/${modeRef.current}-user`
        const response = await axios.post(url, {...selectedUser})
        result = response?.data
        
      } catch (error) {
        throw error
      }
    }
    return result
  }, {
    onError: (error) => {
      console.log("[mutateUser:onError] error: ", error)
      let retval = errorHandler(error)
      setMsg(retval);
    },
    onSuccess: (response) => {
      // console.log("[mutateUser:onSuccess] response: ", response)
      toast.info(response?.message, {
        position: "bottom-right"
      })
      queryClient.invalidateQueries("users");
    }
  })

  const submitHandler = () => {
    console.log("* submitHandler init ==");
    console.log("[submitHandler] selectedUser: ", selectedUser);
    msg && setMsg(null);
    setModalIsOpen(false)
    mutateUser.mutate()
  };

  const handleDelete = () => {
    console.log("*handleDelete init");
    // console.log("selectedUser: ", selectedUser);
    modalIsOpen && setModalIsOpen(false)
    msg && setMsg(null);
    // setMode("_deleteUser")
    modeRef.current = "_deleteUser"
    mutateUser.mutate();
  };
  
  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} />
      <ToastContainer theme='dark'/>
      <section className="primary">
        {isLoading ? (
          <Skeleton count={20} height={50} circle={true} />
        ) : isError ? (
          <Banner variant='danger'>
            {error?.message ? error.message : error}
          </Banner>
        ) : (
          <>
            {msg && <Banner>{msg}</Banner>}
            <PageMenuHeader>
              <div className="btn-menu-list">
                <Button
                  variant="primary"
                  className="swing-icon"
                  title="Reload"
                  leftGlyph={<FaBolt />}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>

                <Button
                  variant="primary"
                  className="swing-icon"
                  leftGlyph={<FaPlus />}

                  onClick={() => {
                    showModalHandler({mode: "_createUser"});
                  }}
                >
                  Add
                </Button>
              </div>      
            </PageMenuHeader>
            {
              (users && Boolean(users?.length))
                ? (<UsersList users={users} showModal={showModalHandler} />)
                : (<Banner>Users not found.</Banner>)
            }
          </>      
        )}
        <ModalUser 
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          title={"User"}
          mode={mode}
          user={selectedUser}
          setUser={setSelectedUser}
          submitHandler={submitHandler}
          handleDelete ={handleDelete}
        />
        <ScrollTop />
      </section>
    </>
  )
}
