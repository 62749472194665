import axios from "axios";
import { errorHandler } from "./errorHandler";
import socketIOClinet from "socket.io-client";
import _ from "lodash";

export const getSettings = () => {
  console.log("* utils:getSettings init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window?.printflo_api?._settings();
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get("/api/settings");
          result = response.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:getSettings] result: ", result);
      // if (result && !_.isEmpty(result))
      //   window.localStorage.setItem("_settings", JSON.stringify(result));
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.error("[utils:getSettings] retval: ", retval);
      reject(retval);
    }
  });
};

export const getHostname = () => {
  console.log("* utils:getHostname init");
  return new Promise(async (resolve, reject) => {
    try {
      const storedHostname = window.localStorage.getItem("_hostname");
      console.log(
        "[utils:getHostname] storedHostname: ",
        storedHostname,
        typeof storedHostname
      );
      if (storedHostname && storedHostname !== "undefined") {
        resolve(storedHostname);
        return;
      } else {
        let result;
        if (window?.printflo_api) {
          result = await window?.printflo_api?._hostname();
          if (_.isError(result)) throw result;
        } else {
          const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);
          const promise = () => {
            return new Promise((resolve) => {
              socket.emit("on-get-hostname", (response) => {
                resolve(response);
              });
            });
          };
          result = await promise();
        }
        console.log("[utils:getHostname] result: ", result);
        window.localStorage.setItem("_hostname", result);
        resolve(result);
      }
    } catch (error) {
      const retval = errorHandler(error);
      console.error("[utils:getHostname] retval: ", retval);
      reject(retval);
    }
  });
};

export const updateSettings = (payload) => {
  console.log("* utils:updateSettings init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updateSettings(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post("/api/settings/update", payload);
          // console.log(
          //   "[utils:updateSettings:axios.post] response.data: ",
          //   response?.data
          // );
          result = response.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:updateSettings] result: ", result);
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.error("[utils:updateSettings] retval: ", retval);
      reject(retval);
    }
  });
};
