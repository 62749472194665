import { labelTemplateQR } from "./labelTemplates";
import socketIOClient from "socket.io-client";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

export const printOrderItemLabel = ({
  item,
  settings,
  type,
  isPicked,
  areaCode,
}) => {
  // console.log("* utils:printOrderItemLabel init");
  // console.log("[utils:printOrderItemLabel] item: ", item);
  // console.log("[utils:printOrderItemLabel] settings: ", settings);
  // console.log("[utils:printOrderItemLabel] type: ", type);
  // console.log("[utils:printOrderItemLabel] isPicked: ", isPicked);
  // console.log("[utils:printOrderItemLabel] areaCode: ", areaCode);
  const { platens, printLabelByGraphicPositions } = settings || [];
  // console.log("[utils:printOrderItemLabel] platens: ", platens);
  // console.log(
  //   "[utils:printOrderItemLabel] printLabelByGraphicPositions: ",
  //   printLabelByGraphicPositions
  // );
  // console.log("[utils:printOrderItemLabel] useVirtualZPL: ", useVirtualZPL);

  let retValLabel;
  if (!printLabelByGraphicPositions) {
    if (item?._item?._graphics && Boolean(item?._item?._graphics.length)) {
      let tempGraphicPositionArr = [];
      for (let g of item?._item?._graphics) {
        if (g?.graphicPosition) tempGraphicPositionArr.push(g.graphicPosition);
      }
      if (Boolean(tempGraphicPositionArr.length)) {
        item._item.graphicPosition = tempGraphicPositionArr.join(",");
      }
    }
    retValLabel = labelTemplateQR({
      item,
      platens,
      settings,
      type,
      isPicked,
      areaCode,
    });
    const { zpl, totalQtyToPrint } = retValLabel;
    if (window?.printflo_api) {
      window.printflo_api._zplPrint({ template: zpl });
    } else {
      socket.emit("on-sku-print", { template: zpl }, (cb) => {
        console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb);
      });
    }
    return { totalQtyToPrint };
  } else {
    if (item?._item?._graphics.length > 1) {
      for (let graphic of item._item._graphics) {
        // console.log('- graphic: ', graphic)
        if (item?._item?.graphicPosition)
          item._item.graphicPosition = graphic.graphicPosition;
        retValLabel = labelTemplateQR({
          item,
          platens,
          settings,
          type,
          isPicked,
          areaCode,
        });
        const { zpl, totalQtyToPrint } = retValLabel;
        if (window?.printflo_api) {
          window.printflo_api._zplPrint({ template: zpl });
        } else {
          socket.emit("on-sku-print", { template: zpl }, (cb) => {
            console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb);
          });
        }
        return { totalQtyToPrint };
      }
    } else {
      if (item?._item?._graphics.length === 1) {
        item._item.graphicPosition = item._item._graphics[0]?.graphicPosition;
      }
      retValLabel = labelTemplateQR({
        item,
        platens,
        settings,
        type,
        isPicked,
        areaCode,
      });
      const { zpl, totalQtyToPrint } = retValLabel;
      if (window?.printflo_api) {
        window.printflo_api._zplPrint({ template: zpl });
      } else {
        socket.emit("on-sku-print", { template: zpl }, (cb) => {
          console.log("[handlePrintOrderItemLabel:on-sku-print] cb: ", cb);
        });
      }
      return { totalQtyToPrint };
    }
  }
};
