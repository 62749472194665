import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const fetchWorkOrders = (payload) => {
  console.log("* utils:orders:fetchWorkOrders init");
  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchWorkOrders(payload);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.get("/api/orders", {
            params: { ...payload },
          });
          console.log(
            "[utils:orders:fetchWorkOrders:axios.get] response: ",
            response
          );
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:orders:fetchWorkOrders] results: ", results);
      resolve(results);
    } catch (error) {
      const retVal = errorHandler(error);
      console.error("[utils:orders:fetchWorkOrders] retVal:error: ", retVal);
      reject(retVal);
    }
  });
};

export const fetchWorkOrder = ({ orderId, orderNumber, bin }) => {
  console.info("* utils:fetchWorkOrder init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchWorkOrder({
          orderId,
          orderNumber,
          bin,
        });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/orders/get-order/${orderId}`);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:orders:fetchWorkOrder] error: ",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const getOrderId = (payload) => {
  console.log("* utils:getOrderId init");
  console.log("[utils:getOrderId] payload: ", payload);
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._getOrderId(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get("/api/orders/get-orderId", {
            params: payload,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:getOrderId] result: ", result);
      resolve(result);
    } catch (error) {
      console.error("getOrderId error: ", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const createManualOrder = (order) => {
  console.info("* utils:orders:createManualOrder init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._createManualOrder({ ...order });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/orders/create`, { ...order });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }

      // console.info("[utils:orders:createManualOrder] result: ", result);
      resolve(result);
    } catch (error) {
      console.error(
        "[utils:orders:createManualOrder] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const updateOrder = (data) => {
  console.info("* utils:orders:updateOrder init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updateOrder({ ...data });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/orders/update`, { ...data });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.info("[utils:orders:updateOrder] result: ", result);
      resolve(result);
    } catch (error) {
      console.error("[utils:orders:updateOrder] error", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const deleteLocalOrder = (orderId) => {
  console.log("* utils:orders:deleteLocalOrder init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._deleteOrder({ orderId });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.delete(`/api/orders/delete/${orderId}`);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log("[utils:orders:deleteLocalOrder] result: ", result);
    } catch (error) {
      console.error(
        "[utils:orders:deleteLocalOrder] error",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const updateOrdersPickedAsOrdered = () => {
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updateOrdersPickedAsOrdered();
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(
            "/api/orders/update-picked-ordered"
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      console.log(
        "[utils:updateOrdersPickedAsOrdered] error: ",
        errorHandler(error)
      );
      reject(errorHandler(error));
    }
  });
};

export const findOrders = (payload) => {
  console.info("* utils:orders:findOrders init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchFindWorkOrders(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post("/api/orders/find-orders", payload);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(result);
    } catch (error) {
      console.error("[utils:orders:findOrders] error", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};
