import _ from "lodash";
import axios from "axios";
import { errorHandler } from "./errorHandler";

export const fetchShipStationOrders = ({ page, pageSize }) => {
  console.info("* utils:shipstation:fetchShipStationOrders init");
  return new Promise(async (resolve, reject) => {
    console.log("[utils:shipstation:fetchShipStationOrders] page: ", page);
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchShipStationOrders({
          page,
          pageSize,
        });
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.get(`/api/orders/fetch-orders`, {
            params: { page, pageSize },
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          });
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log(
      //   "[utils:shipstation:fetchShipStationOrders] results: ",
      //   results
      // );
      resolve(results);
    } catch (error) {
      let retval = errorHandler(error);
      console.log(
        "[utils:shipstation:fetchShipStationOrders] retval: ",
        retval
      );
      reject(retval);
    }
  });
};

export async function getShipStationOrdersByStatus({ orderStatus }) {
  return new Promise(async (resolve, reject) => {
    try {
      console.info(
        "[utils:getShipStationOrdersByStatus] orderStatus: ",
        orderStatus
      );
      const result = await window.printflo_api._fetchShipStationOrdersByStatus({
        orderStatus,
      });
      // console.log("[utils:getShipStationOrdersByStatus] result: ", result);
      if (_.isError(result)) throw result;

      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log(
        "[utils:shipstation:getShipStationOrdersByStatus] retval: ",
        retval
      );
      reject(retval);
    }
  });
}

export async function getShipStationOrder(orderId) {
  console.log("* utils:getShipStationOrder init");
  return new Promise(async (resolve, reject) => {
    let result;
    console.log("[utils:getShipStationOrder] orderId: ", orderId);
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchShipStationOrder({
          orderId,
        });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(
            `/api/ship-station/orders/${orderId}`
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log("[utils:getShipStationOrder] result: ", result);
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:shipstation:getShipStationOrder] retval: ", retval);
      reject(retval);
    }
  });
}

export const fetchListShipments = (payload) => {
  console.log("* utils:fetchListShipments init");
  return new Promise(async (resolve, reject) => {
    try {
      let result;
      // console.log("[utils:fetchListShipments] payload: ", payload);
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchListShipments(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/ship-station/list-shipments`, {
            params: { ...payload },
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:fetchListShipments] result: ", result);
      resolve(result);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:shipstation:fetchListShipments] retval: ", retval);
      reject(retval);
    }
  });
};

export async function createShippingLabel(order, orderNumber, isTestLabel) {
  console.log("* utils:createShippingLabel init");
  return new Promise(async (resolve, reject) => {
    console.log("[utils:createShippingLabel] order: ", order);
    console.log("[utils:createShippingLabel] orderNumber: ", orderNumber);
    console.log("[utils:createShippingLabel] isTestLabel: ", isTestLabel);
    try {
      order["testLabel"] = isTestLabel;
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._createShippingLabel({
          order,
          orderNumber,
        });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(
            "/api/ship-station/create-label-for-order",
            { ...order },
            { params: { orderNumber } }
          );
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log(
        "[utils:createShippingLabel] result: ",
        result?.trackingNumber
      );
      resolve(result);
    } catch (error) {
      console.log("[utils:shipstation:createShippingLabel] error: ", error);
      // let retval = errorHandler(error);
      // console.log("[utils:shipstation:createShippingLabel] retval: ", retval);
      reject(error);
    }
  });
}

export function getWarehouse(warehouseId) {
  console.log("* utils:getWarehouse init");
  return new Promise(async (resolve, reject) => {
    // console.log("[utils:getWarehouse] warehouseId: ", warehouseId);
    try {
      let result;
      if (window?.printflo_api) {
        result = await window.printflo_api._getWarehouse({ warehouseId });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(
            `/api/ship-station/warehouse/${warehouseId}`
          );
          result = response?.data;
        } catch (error) {
          // console.log("[utils:getWarehouse:axios] error: ", error);
          throw error;
        }
      }
      // console.log("[utils:getWarehouse] result: ", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:shipstation:getWarehouse] error: ", error);
      let retval = errorHandler(error);
      // console.log("[utils:shipstation:getWarehouse] retval: ", retval);
      reject(retval);
    }
  });
}

export const getTags = () => {
  console.log("* utils:shipstation:getTags init");
  const storedTags = window.localStorage.getItem("_tags");
  // console.log("[utils:shipstation:getTags] storedTags: ", storedTags);
  if (storedTags !== "undefined") {
    if (_.isArray(storedTags)) return storedTags;
  }

  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._getTags();
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.get(`/api/ship-station/tags`);
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log("[getTags] results: ", results);
      window.localStorage.setItem("_tags", JSON.stringify(results));
      resolve(results);
    } catch (error) {
      console.log("[utils:shipstation:getTags] error: ", error);
      let retval = errorHandler(error);
      // console.log("[utils:shipstation:getTags] retval: ", retval);
      reject(retval);
    }
  });
};

export const addFunds = ({ carrierCode }) => {
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._addFunds({ carrierCode });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/ship-station/add-funds`, {
            carrierCode,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.log("[addFunds] result: ", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:shipstation:addFunds] error: ", error);
      let retval = errorHandler(error);
      console.log("[utils:shipstation:addFunds] retval: ", retval);
      reject(retval);
    }
  });
};
