import React, {useRef, useState, useEffect} from 'react'
import ModalTemplate from './ModalTemplate'
import { FaLock, FaSave, FaTrashAlt, FaUnlock } from 'react-icons/fa';
import Button from '@leafygreen-ui/button'

const roles = [
  { value: "admin", label: "Admin" },
  { value: "operator", label: "Operator" },
];

export default React.memo(function ModalUser({
  modalIsOpen,
  setModalIsOpen,
  title,
  mode,
  user,
  setUser,
  submitHandler,
  handleDelete
}) {

  const formRef = useRef();
  const usernameRef = useRef();
  const [pwHidden, setPwHidden] = useState(true);
  
  useEffect(() => {
    // console.log('- pwHidden: ', pwHidden)
    if(!pwHidden) setPwHidden(true)
    if(mode==='create' && !pwHidden) setPwHidden(true)
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(mode && usernameRef?.current) {
      mode === "create" && usernameRef.current.focus();
    }
  }, [mode, usernameRef]);

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.reportValidity();
    if (formRef.current.checkValidity()) {
      setModalIsOpen(false)
      submitHandler();
    } 
  };

  const onChangeHandler = (e) => {
    // console.log(e.target.name, e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };


  const deleteHandler = (e) => {
    e.preventDefault()
    const confirm = window.confirm('Confirm to delete?')
    if (confirm) {
      handleDelete()
    }
  }

  const onCloseModal = () => {
    console.log('- onCloseModal init')
  }

  const onAfterCloseModal = () => {
    // console.log('* onAfterCloseModal init')
    if(!pwHidden) setPwHidden(true)
  }

  return (
    <ModalTemplate
      title={title}
      subTitle={mode}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalSize={{ width: "400px", height: "auto" }}
      closeModal={onCloseModal}
      onAfterCloseModal={onAfterCloseModal}
    >
      <form className="card modal-form" ref={formRef}>
        <div className="input-field">
          <label htmlFor="username">
            User Id
          </label>
          <input
            name="username"
            type="text"
            value={user.username ? user.username : ""}
            onChange={onChangeHandler}
            placeholder="User Id"
            required={true}
            autoFocus
            ref={usernameRef}
          />
        </div>
        <div className="input-field password-wrapper">
          <label htmlFor="password">
            Password
          </label>
          <input
            name="password"
            type={pwHidden ? "password" : "text" }
            value={user.password ? user.password : ""}
            onChange={onChangeHandler}
            placeholder="Password"
          />
          { 
            pwHidden 
            ? <FaUnlock className="fa-password-icon" onClick={() => setPwHidden((current) => !current)}/> 
            : <FaLock className="fa-password-icon" onClick={() => setPwHidden((current) => !current)}/>
          }
        </div>
        <div className="input-field">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={user.role}
            onChange={onChangeHandler}
            required
          >
            <option value="">-- Choose a role --</option>
            {roles.map((r, index) => {
              return (
                <option value={r.value} key={index}>
                  {r.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="modal-footer">
          {mode!=='_createUser' && (
            <Button
              variant="danger"
              className="swing-icon"
              onClick={deleteHandler}
              leftGlyph={<FaTrashAlt /> }
            >
              Delete
            </Button>
          )}
          <Button variant='primary' onClick={handleSubmit} className="swing-icon" leftGlyph={<FaSave />}>
            Submit
          </Button>
        </div>

      </form>
    </ModalTemplate>
  )
})
