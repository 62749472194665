import axios from "axios";
import _ from "lodash";
import { errorHandler } from "./errorHandler";

export function authenticate({ username, password }) {
  console.log("* utils:authenticate init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._authenticate({
          username,
          password,
        });
        console.log(
          "[utils:authenticate:window.printflo_api] result: ",
          result
        );
        console.log(
          "[utils:authenticate:window.printflo_api] result:isError ",
          _.isError(result)
        );
        if (_.isError(result)) {
          throw result;
        }
      } else {
        try {
          const response = await axios.post("/api/auth/authenticate", {
            username,
            password,
          });
          // console.log("[utils:authenticate:axios.post] response.data: ", response?.data);
          result = response?.data;
        } catch (error) {
          console.log("[utils:authenticate:axios.post] error: ", error);
          console.log(
            "[utils:authenticate:axios.post] error.response: ",
            error?.response
          );
          throw error;
          // if (error?.response?.data) {
          //   result = error.response.data;
          // } else {
          //   reject(errorHandler(error));
          // }
        }
      }
      // console.log("[utils:authenticate] result: ", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:authenticate] error: ", error);
      // let retval = errorHandler(error);
      // console.log("[utils:authenticate] retval : ", retval);
      reject(error);
    }
  });
}

export const fetchUsers = (payload) => {
  console.log("* utils:auth:fetchUsers init");
  return new Promise(async (resolve, reject) => {
    console.log("[utils:auth:fetchUsers] payload: ", payload);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchUsers(payload);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get("/api/auth/users", {
            params: payload,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:auth:fetchUsers] result: ", result);
      const parsedUsers = result.map((u) => {
        return { ...u, password: atob(u.password) };
      });
      resolve(parsedUsers);
    } catch (error) {
      let retval = errorHandler(error);
      console.log("[utils:auth:fetchUsers] error retval: ", retval);
      reject(retval);
    }
  });
};
