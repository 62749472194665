import axios from "axios";
import { errorHandler } from "./errorHandler";
import _ from "lodash";

export const getGraphics = (data) => {
  console.log("* utils:getGraphics init");
  return new Promise(async (resolve, reject) => {
    let results;
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._fetchGraphics(data);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.post("/api/graphics", data);
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(results);
    } catch (error) {
      console.log("[utils:getGraphics] error: ", error);
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const submitGraphic = (data) => {
  console.log("* utils:submitGraphic init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._submitGraphic(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post("/api/graphics/update", data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.log("[utils:submitGraphic] result: ", result);
      resolve(result);
    } catch (error) {
      console.log("[utils:submitGraphic] error: ", error);
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const deleteGraphic = (query) => {
  console.log("* utils:deleteGraphic init");
  return new Promise(async (resolve, reject) => {
    console.log("[utils:deleteGraphic] query: ", query);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._deleteGraphic(query);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.delete(`/api/graphics/delete`, {
            params: query,
          });
          result = response?.data;
        } catch (error) {
          throw result;
        }
      }
      resolve(result);
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};

export const searchGraphics = (payload) => {
  console.log("* utils:searchGraphics init");
  console.log("[utils:searchGraphics] payload: ", payload);
  let results;

  return new Promise(async (resolve, reject) => {
    try {
      if (window?.printflo_api) {
        results = await window.printflo_api._searchGraphics(payload);
        if (_.isError(results)) throw results;
      } else {
        try {
          const response = await axios.post("/api/graphics/search", payload);
          results = response?.data;
        } catch (error) {
          throw error;
        }
      }
      resolve(results);
    } catch (error) {
      const retval = errorHandler(error);
      reject(retval);
    }
  });
};
